// Default imports
import React from "react";
import { Row, Col, Container } from "react-bootstrap";

import SectionHeader from "../components/SectionHeader";
import ButtonText from "../components/ButtonText";

import slugify from "slugify";

const GenericWideSection = ({
  header,
  category,
  label,
  slug,
  text,
  textAlign,
  button,
  buttonAlign,
  children,
  vertical,
  smallContent,
  containerClassName,
  contentClassName,
}) => {
  let col1sizes, col2sizes;
  switch (true) {
    case vertical:
      col1sizes = { xs: "24", lg: "24" };
      col2sizes = { xs: "24" };
      break;

    case smallContent:
      col1sizes = {
        xs: "24",
        md: "12",
        lg: "12",
        xl: "8",
      };
      col2sizes = {
        xs: "24",
        md: { span: "11", offset: "1" },
        lg: { span: "11", offset: "1" },
        xl: { span: "10", offset: "2" },
      };
      break;

    default:
      col1sizes = {
        xs: "24",
        md: "9",
      };
      col2sizes = {
        xs: "24",
        md: { span: "14", offset: "1" },
      };
  }

  return (
    <Container
      className={`generic-wide-section ${vertical ? "-vertical" : ""} ${
        containerClassName || ""
      }`}
      style={{ textAlign }}
      id={
        slug ||
        slugify(header || "section", {
          lower: true,
          strict: true,
          locale: "pl",
        })
      }
    >
      <Row className="generic-wide-section__wrapper">
        {(category || header || text) && (
          <Col className="generic-wide-section__desc" {...col1sizes}>
            {category && (
              <div className="generic-wide-section__category">{category}</div>
            )}
            {header && (
              <div className="generic-wide-section__headline">
                <SectionHeader header={header} labelTop={label} />
              </div>
            )}
            {text && (
              <div
                className="generic-wide-section__text"
                dangerouslySetInnerHTML={{ __html: text }}
              />
            )}
          </Col>
        )}

        <Col
          className={`generic-wide-section__content-wrapper ${
            contentClassName || ""
          }`}
          {...col2sizes}
        >
          {children}
        </Col>
      </Row>
      {button?.link && (
        <div
          className="generic-wide-section__button-container"
          style={{
            justifyContent: buttonAlign || "center",
          }}
        >
          <ButtonText variant={`small,${button.variant}`} {...button.link}>
            {button.link.title}
          </ButtonText>
        </div>
      )}
    </Container>
  );
};

export default GenericWideSection;
