// Default imports
import React from "react";

import AccordionLinks from "./AccordionLinks";
import GenericWideSection from "./GenericWideSection";

const AccordionLinksSection = ({ content, ...props }) => {
  return (
    <GenericWideSection {...props}>
      <AccordionLinks content={content} />
    </GenericWideSection>
  );
};

export default AccordionLinksSection;
