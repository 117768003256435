// Default imports
import React from "react";
import { ListSpecialContentItem } from "./ListSpecial";

const AccordionLinks = ({ content }) => {
  return (
    <>
      {content?.map((item, i) => (
        <ListSpecialContentItem key={i} {...item} />
      ))}
    </>
  );
};

export default AccordionLinks;
