import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { getCurrentRichContent } from "../helpers/richContentHelpers";

const resourcesPageTplData = (id) => {
  const data = useStaticQuery(
    graphql`
      {
        allWpPage {
          edges {
            node {
              id
              template {
                ... on WpTemplate_Resources {
                  templateName
                  resourcesRichContent {
                    pageHeader {
                      header
                      text
                    }

                    button {
                      link {
                        target
                        title
                        url
                      }
                    }

                    docs {
                      header
                      slug
                      content {
                        ... on WpTemplate_Resources_Resourcesrichcontent_Docs_Content_Text {
                          fieldGroupName
                          header
                          text
                          link {
                            target
                            title
                            url
                          }
                        }
                        ... on WpTemplate_Resources_Resourcesrichcontent_Docs_Content_List {
                          fieldGroupName
                          header
                          textAbove
                          textBelow
                          items {
                            fieldGroupName
                            text
                          }
                        }
                        ... on WpTemplate_Resources_Resourcesrichcontent_Docs_Content_Accordion {
                          fieldGroupName
                          header
                          content {
                            ... on WpTemplate_Resources_Resourcesrichcontent_Docs_Content_Accordion_Content_Text {
                              fieldGroupName
                              header
                              text
                              link {
                                target
                                title
                                url
                              }
                            }
                            ... on WpTemplate_Resources_Resourcesrichcontent_Docs_Content_Accordion_Content_AccordionLink {
                              fieldGroupName
                              type
                              locked
                              link {
                                target
                                title
                                url
                              }
                              file {
                                title
                                localFile {
                                  publicURL
                                }
                              }
                            }
                            ... on WpTemplate_Resources_Resourcesrichcontent_Docs_Content_Accordion_Content_List {
                              fieldGroupName
                              header
                              textAbove
                              textBelow
                              items {
                                fieldGroupName
                                text
                              }
                            }
                          }
                        }
                        ... on WpTemplate_Resources_Resourcesrichcontent_Docs_Content_AccordionLink {
                          fieldGroupName
                          type
                          locked
                          link {
                            target
                            title
                            url
                          }
                          file {
                            title
                            localFile {
                              publicURL
                            }
                          }
                        }
                      }
                    }

                    guides {
                      header
                      slug
                      content {
                        ... on WpTemplate_Resources_Resourcesrichcontent_Guides_Content_Text {
                          fieldGroupName
                          header
                          text
                          link {
                            target
                            title
                            url
                          }
                        }
                        ... on WpTemplate_Resources_Resourcesrichcontent_Guides_Content_List {
                          fieldGroupName
                          header
                          textAbove
                          textBelow
                          items {
                            fieldGroupName
                            text
                          }
                        }
                        ... on WpTemplate_Resources_Resourcesrichcontent_Guides_Content_Accordion {
                          fieldGroupName
                          header
                          content {
                            ... on WpTemplate_Resources_Resourcesrichcontent_Guides_Content_Accordion_Content_Text {
                              fieldGroupName
                              header
                              text
                              link {
                                target
                                title
                                url
                              }
                            }
                            ... on WpTemplate_Resources_Resourcesrichcontent_Guides_Content_Accordion_Content_AccordionLink {
                              fieldGroupName
                              type
                              locked
                              link {
                                target
                                title
                                url
                              }
                              file {
                                title
                                localFile {
                                  publicURL
                                }
                              }
                            }
                            ... on WpTemplate_Resources_Resourcesrichcontent_Guides_Content_Accordion_Content_List {
                              fieldGroupName
                              header
                              textAbove
                              textBelow
                              items {
                                fieldGroupName
                                text
                              }
                            }
                          }
                        }
                        ... on WpTemplate_Resources_Resourcesrichcontent_Guides_Content_AccordionLink {
                          fieldGroupName
                          type
                          locked
                          link {
                            target
                            title
                            url
                          }
                          file {
                            title
                            localFile {
                              publicURL
                            }
                          }
                        }
                      }
                    }

                    generalInformation {
                      header
                      slug
                      content {
                        ... on WpTemplate_Resources_Resourcesrichcontent_GeneralInformation_Content_Text {
                          fieldGroupName
                          header
                          text
                          link {
                            target
                            title
                            url
                          }
                        }
                        ... on WpTemplate_Resources_Resourcesrichcontent_GeneralInformation_Content_List {
                          fieldGroupName
                          header
                          textAbove
                          textBelow
                          items {
                            fieldGroupName
                            text
                          }
                        }
                        ... on WpTemplate_Resources_Resourcesrichcontent_GeneralInformation_Content_Accordion {
                          fieldGroupName
                          header
                          content {
                            ... on WpTemplate_Resources_Resourcesrichcontent_GeneralInformation_Content_Accordion_Content_Text {
                              fieldGroupName
                              header
                              text
                              link {
                                target
                                title
                                url
                              }
                            }
                            ... on WpTemplate_Resources_Resourcesrichcontent_GeneralInformation_Content_Accordion_Content_AccordionLink {
                              fieldGroupName
                              type
                              locked
                              link {
                                target
                                title
                                url
                              }
                              file {
                                title
                                localFile {
                                  publicURL
                                }
                              }
                            }
                            ... on WpTemplate_Resources_Resourcesrichcontent_GeneralInformation_Content_Accordion_Content_List {
                              fieldGroupName
                              header
                              textAbove
                              textBelow
                              items {
                                fieldGroupName
                                text
                              }
                            }
                          }
                        }
                        ... on WpTemplate_Resources_Resourcesrichcontent_GeneralInformation_Content_AccordionLink {
                          fieldGroupName
                          type
                          locked
                          link {
                            target
                            title
                            url
                          }
                          file {
                            title
                            localFile {
                              publicURL
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  return getCurrentRichContent(data, id, "resourcesRichContent");
};

export { resourcesPageTplData };
